(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
module.exports = leadingZero;

function leadingZero() {
  return function (input) {

    var output = "0" + input;
    return output; 
  };
}


}).call(this,require("rH1JPG"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/filters/leadingzerofilter.js","/filters")