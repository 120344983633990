(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
module.exports = function($scope, $location, $rootScope, $mdDialog,  $mdSidenav, $firebaseArray, $firebaseObject, $firebaseRef, AuthService, VarService) {
  var vm = this;
  var auth = AuthService;

  var c = firebase.database().ref().child("config/networks");
  vm.networks = $firebaseObject(c);
  vm.tabToFilter = VarService.tabToFilter;

  vm.tabs = ["globe", "smart", "sun cellular", "touch mobile", "talk n text", "abs-cbn mobile", "cherry mobile"];

  vm.currentNavItem = "network-prefixes";
  vm.logout = logout;
  vm.location = false;
  vm.isAdmin = false;
  vm.displayName = "";

  vm.go = go;
  vm.setTable = setTable;
  vm.showDialog = showDialog;
  vm.toggleSidebar = toggleSidebar;
  vm.isLoading = $rootScope.isLoading;

  $rootScope.$watch('isLoading', function(newVal, oldVal) {
    vm.isLoading = newVal;
  });

  auth.$onAuthStateChanged( function(user){
    if (user && user.displayName == "Admin") {
      vm.isAdmin = true;
      $rootScope.isAdmin = true;
    } else {
      vm.isAdmin = false;
      $rootScope.isAdmin = false;
    }
  });

  $scope.$on('$locationChangeStart', function(event) {
    var prefix = "NETWORK PREFIXES";
    var promo = "PROMO DIRECTORY";
    var location = $location.path();

    if(location == "/login") {
      vm.onLogin = true;
    } else if(location == "/users"){
      vm.onUsers = true;
    } else if(location == "/notifications") {
      vm.onNotifications = true;
    } else {
      vm.onLogin = false;
      vm.onUsers = false;
      vm.onNotifications = false;
    }

    switch(location) {
      case "/network-prefixes":
        vm.title = prefix;
        break;
      case "/promo-directory":
        vm.title = promo;
        break;
    }
  });

  function showDialog(ev, el, newNetwork) {
    $mdDialog.show({
      contentElement: el,
      parent: angular.element(document.body),
      targetEvent: ev,
      clickOutsideToClose: true,
      onShowing: function() {
        if(newNetwork) {
          VarService.newNetwork = true;
          VarService.fromDialog = true;
          VarService.newNetworkItem = vm.searchText;
        }
      },
      onRemoving: function() {
        if(VarService.newNetwork) {
          VarService.newNetwork = false;
        }
      }
    });
  }

  function setTable(tab) {
    VarService.tabToFilter = tab;
  }

  function logout() {

    auth.$signOut();

    auth.$onAuthStateChanged( function(authData){
      $location.path( "/login" );
    });
  }

  function locationFn() {
    var location = $location.path().replace(/[/]/g, "");
    vm.currentNavItem = location;
  }

  function go(val, mobile) {

    if(val == "log-out") {
      logout();
    } else {
      $location.path(val);
    }

    if(mobile) {
      toggleSidebar();
    }
  }

  function buildToggler(componentId) {
    return function() {
      $mdSidenav(componentId).toggle();
    };
  }

  function toggleSidebar() {
    $mdSidenav('left').toggle();
  }

  $rootScope.$on("$routeChangeStart", function(next, current) {
    locationFn();
  });
};
}).call(this,require("rH1JPG"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/controllers/mainctrl.js","/controllers")