(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
module.exports = accord;

function accord() {

    return {
      controller: function($scope,$rootScope, $element) {

        $scope.toggleAccord = function(){
          var el = angular.element($element);
          var maxHeight = parseInt(el.css("max-height"));
          var scrollHeight = el[0].scrollHeight + 84;

          if(maxHeight) {
            el.css("max-height", "0px");
          } else {
            el.css("max-height", scrollHeight + "px");
          }
        }; 

        $rootScope.$on('ToggleAccord',function(type){
           $scope.toggleAccord();
        });


      },
    };
}
}).call(this,require("rH1JPG"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/directives/accorddirective.js","/directives")