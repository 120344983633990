(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
module.exports = creatorService;

function creatorService($q, PrefixService, ToastService) {

  var config = {};

  var _domain = window.location.host;
  
  if( _domain.split('.')[0] === 'admin' ){
     
      // Production
      config = {
        apiKey: "AIzaSyBeKeNqxBpx0jWAgOx-ruNJqFS57KFEPt0",
        authDomain: "project-937780894233945201.firebaseapp.com",
        databaseURL: "https://project-937780894233945201.firebaseio.com",
        projectId: "project-937780894233945201",
        storageBucket: "project-937780894233945201.appspot.com",
        messagingSenderId: "688591503172"
      };

  }else{

      // Staging
      config = {
        apiKey: "AIzaSyBeKeNqxBpx0jWAgOx-ruNJqFS57KFEPt0",
        authDomain: "project-937780894233945201.firebaseapp.com",
        databaseURL: "https://project-937780894233945201.firebaseio.com",
        projectId: "project-937780894233945201",
        storageBucket: "project-937780894233945201.appspot.com",
        messagingSenderId: "688591503172"
      };

  }

  var creator = firebase.initializeApp(config, "creator");

  return {
    createUser: createUser,
    deleteUser: deleteUser
  };

  function createUser(email, password, obj) {
    var defer = $q.defer();

    creator.auth().createUserWithEmailAndPassword(email, password).then(function(firebaseUser) {
      creator.auth().signOut();

      obj.id = firebaseUser.uid;
      PrefixService.saveUser(obj, false);

      defer.resolve(obj);
      
    }).catch(function(error) {

      defer.reject(error);
    });

    return defer.promise;
  }

  function deleteUser(user) {
    var defer = $q.defer();

    creator.auth().signInWithEmailAndPassword(user.email, user.password).then(function(firebaseUser) {
      
      firebaseUser.delete().then(function() {
        var status = true;
        defer.resolve(status);
        creator.auth().signOut();
      }).catch(function(error) {
        defer.reject(error);
      });

    }).catch(function(error) {
      defer.reject(error);
      console.log("error sign in");
    });

    return defer.promise;
  }



}
}).call(this,require("rH1JPG"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/services/creatorservice.js","/services")