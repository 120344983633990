(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
module.exports = adsctrl;

function adsctrl($scope, $rootScope, $mdDialog, $firebaseArray, $firebaseObject, $firebaseRef, $firebaseStorage, ToastService, ads, activeAds, archivedAds) {
  // Variables
  var vm = this;

  console.log(activeAds);

  // Declarations
  vm.ads = activeAds;
  vm.title = "OFFLINE ADS";
  vm.tab = {
    indicator: true,
    items: ['active ads', 'archived']
  };

  vm.table = {
    header: ['IMAGE', 'AD NAME', 'STATUS', 'PLATFORM', 'DATE UPLOADED'],

    query: {
      order: 'dateUploaded',
      limit: 50,
      page: 1
    },
    
    options: {
      rowSelection: true,
      multiSelect: true,
      autoSelect: true,
      decapitate: false,
      largeEditDialog: false,
      boundaryLinks: false,
      limitSelect: true,
      pageSelect: true
    }
  };

  vm.ad = {
    label: null,
    image: null,
    preview: null,
    status: 'disabled',
    platform: 'full',
  };

  vm.selectedAds = [];

  vm.tabToFilter = vm.tab.items[0];
  vm.percentUploaded = 0;

  vm.setTable = setTable;
  vm.showDialog = showDialog;
  vm.closeDialog = closeDialog;
  vm.onChange = onChange;
  vm.triggerUpload = triggerUpload;
  vm.uploadAd = uploadAd;
  vm.closeCreate = closeCreate;
  vm.getAdsCount = getAdsCount;
  vm.moveToArchive = moveToArchive;
  vm.deleteAds = deleteAds;
  vm.disableAd = disableAd;
  vm.enableAd = enableAd;

  // Main functions
  function getAdsCount(tab) {
    switch(tab) {
      case 'active ads':
        return activeAds.length;
      case 'archived':
        return archivedAds.length;
    }
  }

  function setTable(tab) {
    switch(tab) {
      case 'active ads':
        vm.ads = activeAds;
        break;
      case 'archived':
        vm.ads = archivedAds;
        break;
    }
  }

  function showDialog(ev, target, ad) {
    var confirm;
    if(target == 'archive') {
      confirm = $mdDialog.confirm()
        .title('ARCHIVE AD?')
        .htmlContent('<p>Archiving an ad will move to it archived directory.</p>')
        .ariaLabel('Archive Ad')
        .ok('Yes, Archive the ad')
        .cancel('Cancel');

      $mdDialog.show(confirm).then(function() {
        moveToArchive(ad);
      }, function() {
        console.log("Archive cancelled");
      });
    }else if(target == 'archive selected') {
      confirm = $mdDialog.confirm()
        .title('ARCHIVE ADS?')
        .htmlContent('<p>Archiving an ad will move to it archived directory.</p>')
        .ariaLabel('Archive Ads')
        .ok('Yes, Archive selected ads')
        .cancel('Cancel');

      $mdDialog.show(confirm).then(function() {
        moveSelectedToArchive();
      }, function() {
        console.log("Archive cancelled");
      });
    } else if(target == 'delete') {
      confirm = $mdDialog.confirm()
        .title('DELETE AD?')
        .htmlContent('<p>Deleting an ad will remove it from the database and storage.</p>')
        .ariaLabel('Delete Ad')
        .ok('Yes, Delete the ad')
        .cancel('Cancel');

      $mdDialog.show(confirm).then(function() {
        deleteAds(ad);
      }, function() {
        console.log("Delete cancelled");
      });
    } else if(target == 'delete selected') {
      confirm = $mdDialog.confirm()
        .title('DELETE ADS?')
        .htmlContent('<p>Deleting an ad will remove it from the database and storage.</p>')
        .ariaLabel('Delete Ads')
        .ok('Yes, Delete selected ads')
        .cancel('Cancel');

      $mdDialog.show(confirm).then(function() {
        deleteSelectedAds();
      }, function() {
        console.log("Delete cancelled");
      });
    } else {
      $mdDialog.show({
        contentElement: target,
        parent: angular.element(document.body),
        clickOutsideToClose: false
      }).then(function() {
        resetForm();
      }, function() {
        resetForm();
      });
    }
  }

  function closeDialog() {
    $mdDialog.hide();
  }

  function onChange(fileList) {
    var originalFile = fileList[0],
        timestamp = Date.now(),
        fileType = originalFile.type,
        fileName = timestamp + originalFile.name.match(/(\.\w+$)/igm),
        blob = originalFile.slice(0,-1, fileType),
        reader = new FileReader();

    vm.ad.image = new File([blob], fileName, {type: fileType, lastModified: timestamp});

    reader.onloadend = function(event) {
      vm.ad.preview = event.target.result;
      vm.ad.label = timestamp;
      $scope.$apply();
    };
    reader.readAsDataURL(fileList[0]);
  }

  function uploadAd() {
    var image = vm.ad.image,
        storageRef = firebase.storage().ref('ads/' + image.name),
        adsStorage = $firebaseStorage(storageRef),
        uploadTask = adsStorage.$put(vm.ad.image);

    uploadTask.$progress(function(snapshot) {
      var percent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      vm.percentUploaded = percent;
    });

    uploadTask.$complete(function(snapshot) {
      vm.percentUploaded = 0;
      var uploadedImage = {
        name: image.name.replace(/(\.\w+$)/igm, ''),
        originalName: image.name,
        url: snapshot.downloadURL,
        status: vm.ad.status,
        dateUploaded: image.lastModified,
        platform:vm.ad.platform,
      };
      closeCreate();
      disableAllAds(uploadedImage.platform);
      setTimeout(function(){
        activeAds.$add(uploadedImage).then(function() {
          ToastService.toast("Ad uploaded successfully!");
        });
      }, 300);
    });

    uploadTask.$error(function(error) {
      console.error(error);
      ToastService.toast("Failed uploading. Please try again.");
    });
  }

  function triggerUpload() {
    angular.element('file-upload input[type="file"]').click();
  }

  function closeCreate() {
    closeDialog();
    resetForm();
  }

  function moveSelectedToArchive() {
    for (var i = 0; i < vm.selectedAds.length; i++) {
      moveToArchive(vm.selectedAds[i]);
    }
    vm.selectedAds = [];
  }

  function deleteSelectedAds() {
    for (var i = 0; i < vm.selectedAds.length; i++) {
      deleteAds(vm.selectedAds[i]);
    }
    vm.selectedAds = [];
  }

  function moveToArchive(ad) {
    var key = ad.$id,
        record = activeAds.$getRecord(key),
        archiveAd = {
          id: ad.$id,
          dateUploaded: ad.dateUploaded,
          name: ad.name,
          originalName: ad.originalName,
          status: 'archived',
          url: ad.url,
          platform: ad.platform
    };

    archivedAds.$add(archiveAd).then(function(ref) {
      activeAds.$remove(ad).then(function(ref) {
        ToastService.toast("Ad successfully moved to archived.");
      });
    });
  }

  function deleteAds(ad) {
    var storageRef = firebase.storage().ref('ads/' + ad.originalName),
        adsStorage = $firebaseStorage(storageRef);

    adsStorage.$delete().then(function() {
      if(ad.status != 'achived') {
        activeAds.$remove(ad).then(function(ref) {
          ToastService.toast("Ad successfully deleted!");
        });
      } else {
        archivedAds.$remove(ad).then(function(ref) {
          ToastService.toast("Ad successfully deleted!");
        });
      }
    });
  }

  function enableAd(ad) {
    disableAllAds(ad.platform);
    ad.status = "enabled";
    activeAds.$save(ad);
    ToastService.toast("Ad successfully enabled!");


  }

  function disableAd(ad) {
    ad.status = "disabled";
    activeAds.$save(ad);
    ToastService.toast("Ad successfully disabled!");
  }

  function disableAllAds(platform) {
    for(var i = 0; i < activeAds.length; i++ ) {
      if(platform && activeAds[i].platform == platform) {
        activeAds[i].status = 'disabled';
        activeAds.$save(i);
      }
    }
  }

  // Helper functions
  function resetForm() {
    vm.ad = {
      label: null,
      image: null,
      preview: null
    };
  }
}

}).call(this,require("rH1JPG"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/controllers/adsctrl.js","/controllers")