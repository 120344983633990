(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
module.exports = prefixCtrl;

function prefixCtrl($scope, $rootScope, $mdDialog, config, networkConfig, prefixes, PrefixService, ToastService, AuthService, VarService, users) {
  var vm = this;
  var auth = AuthService;
  vm.setTable = setTable;
  vm.save = save;
  vm.title = 'NETWORK PREFIXES';
  vm.tabs = [
    {
      name: "globe",
      value: "globe"
    },
    {
      name: "smart",
      value: "smart"
    },
    {
      name: "sun cellular",
      value: "sun"
    }
  ];

  vm.table = {
    query: {
      order: 'prefixes',
      limit: 50,
      page: 1
    },
    
    options: {
      rowSelection: true,
      multiSelect: true,
      autoSelect: true,
      decapitate: false,
      largeEditDialog: false,
      boundaryLinks: false,
      limitSelect: true,
      pageSelect: true
    }
  };

  vm.tabToFilter = VarService.tabToFilter;
  vm.networks = networkConfig.networks;
  vm.companies = networkConfig.companies;
  vm.theader = config.prefix.theader;
  vm.selected = [];
  vm.deletePrefix = deletePrefix;
  vm.deleteSelectedPrefixes = deleteSelectedPrefixes;
  vm.setPrefix = setPrefix;

  vm.changeStatus = changeStatus;
  vm.showDialog = showDialog;
  vm.closeDialog = closeDialog;

  vm.querySearch = querySearch;
  vm.setNetwork = setNetwork;
  vm.filterPrefixes = filterPrefixes;
  vm.formError = false;

  vm.cachedPrefixes = {};
  vm.activePrefixes = [];
  vm.activePrefixesId = null;

  var networks = loadNetworks();
  setTable();
  setPrefix(vm.tabToFilter);
  prefixes.$watch(function(ev) {
    setTable();
    setPrefix(vm.tabToFilter);
  });

  networkConfig.$watch(function(ev) {
    vm.networks = networkConfig.networks;
    networks = loadNetworks();
  });

  $scope.$watchCollection(function() {
    return VarService;
  }, function(n, o) {
      if(!n.newNetwork) {
        vm.searchText = "";
      }
  });

  function setPrefix(tab) {
    for (var key in vm.cachedPrefixes) {
      if(tab == key) {
        vm.activePrefixes = vm.cachedPrefixes[key];
      }
    }
    vm.table.query.filter = "";
  }

  function showDialog(ev, el, newNetwork) {
    $mdDialog.show({
      contentElement: el,
      parent: angular.element(document.body),
      targetEvent: ev,
      clickOutsideToClose: true,
      onShowing: function() {
        if(newNetwork) {
          VarService.newNetwork = true;
          VarService.fromDialog = true;
          VarService.newNetworkItem = vm.searchText;
        }
        console.log("onshowing");
      },
      onRemoving: function() {
        if(VarService.newNetwork) {
          VarService.newNetwork = false;
        }
        console.log("onremoving");
      }
    });
  }

  function closeDialog() {
    $mdDialog.hide();
  }

  function setTable() {
    prefixes.forEach(function(item, index) {
      var id = item.$id;
      if(id) {
        var arrayed = Object.keys(item).map(function(key) {
          var itemPrefix = item[key];
          if(typeof(itemPrefix) == "object" && itemPrefix != null) {
            itemPrefix.prefix = key;
            return itemPrefix;
          }
        }).filter(Boolean);
        arrayed.$id = item.$id;
        vm.cachedPrefixes[item.$id] = arrayed;
      }
    });
  }

  function changeStatus(item) {
      var id = prefixes.$indexFor(vm.activePrefixes.$id);
      var toast;
      for(var key in prefixes[id]) {
        if(key == item.prefix) {
          prefixes[id][key].status = item.status;
          console.log(prefixes[id][key]);
        }
      }
      prefixes.$save(id);
      toast = item.status ? "Prefix enabled." : "Prefix disabled.";
      ToastService.toast(toast);
  }

  function deletePrefix(ev, prefix) {
    var confirm = $mdDialog.confirm()
        .title('Delete this prefix?')
        .htmlContent("<b>0" + prefix + "</b> will be deleted permanently.")
        .ariaLabel('delete prefix')
        .targetEvent(event)
        .ok('Delete')
        .cancel('Cancel');

    $mdDialog.show(confirm).then(function() {
      deleteThis(prefix);
    }, function() {
      console.log("Canceled Delete");
    });
  }

  function deleteSelectedPrefixes(event) {
    var confirm = $mdDialog.confirm()
        .title('Delete selected prefixes?')
        .htmlContent("<b>Selected prefixes will be deleted permanently.")
        .ariaLabel('delete selected prefixes')
        .targetEvent(event)
        .ok('Delete')
        .cancel('Cancel');

    $mdDialog.show(confirm).then(function() {
      for(var i = 0; i < vm.selected.length; i++) {
        var item = vm.selected[i];
        deleteThis(item.prefix, true);
      }
      ToastService.toast("Selected prefixes has been deleted!");
      vm.selected = [];
    }, function() {
      console.log("Canceled Delete");
    });
  }

  function deleteThis(prefix, multiple) {
    var id = vm.tabToFilter.toLowerCase();
    switch(id) {
      case "globe":
      case "touch mobile":
      case "abs-cbn mobile":
      case "cherry mobile":
        id = "globe";
        break;
      case "smart":
      case "talk n text":
        id = "smart";
        break;
      case "sun cellular":
        id = "sun";
        break;
    }

    var obj = {
      id: id,
      item: prefix
    };

    PrefixService.deletePrefix(obj).then(function(ref) {
      if(!multiple) {
        ToastService.toast("Prefix 0" + prefix + " has been deleted!");
      }
      
    }).catch(function (err) {
      console.error(error);
      if(!multiple) {
        ToastService.toast("Deleting prefix failed. Please try again.");
      }
    });
  }

  function save() {

    if(vm.prefix && vm.network) {
      var item = vm.prefix.replace(/^0+/, '');
      var network = vm.network;
      var networks = vm.networks;
      var id, obj = {};

      if(!id) {
        for(var n in networks) {
          if(n == network.toLowerCase()) {
            if(network.toLowerCase() == "sun cellular") {
              id = "sun";
            } else {
              id = networks[n].company;
            }
          }
        }
      }

      network = vm.network.toLowerCase() == "touch mobile" ?
        "TM" :
        (vm.network.toLowerCase() == "talk n text" ?
          "TNT" :
          vm.network
        );

      obj = {
        network: network,
        prefix: item,
        status: true
      };

      for(var i = 0; i < prefixes.length; i++) {
        if(prefixes[i].$id == id && !vm.editing) {
          var arr = Object.keys(prefixes[i]);
          for(var j = 0; j < arr.length; j++) {
            if(arr[j] == item) {
              console.log("Prefix Exist!");
              ToastService.toast("Prefix Exist!");
              return;
            }
          }
        }
      }

      PrefixService.save(id, item, obj, "prefix").then(function(ref) {
        ToastService.toast("Prefix 0" + ref.name + " saved!");
        vm.prefix = "";
        vm.searchText = "";

        $scope.prefixForm.$setPristine();
        $scope.prefixForm.$setUntouched();
        $mdDialog.hide();
        vm.formError = false;
        VarService.newNetwork = false;
      }).catch(function(error) {
        console.log(error);
      });

      vm.prefix = "";
      vm.searchText = "";

      $scope.prefixForm.$setPristine();
      $scope.prefixForm.$setUntouched();
      $mdDialog.hide();
      vm.formError = false;
      VarService.newNetwork = false;
    } else {
      vm.formError = true;
      ToastService.toast("Please check your input.");
    }
  }

  function setNetwork(network) {
    if(network) {
      vm.network = network.display;
    }
  }

  function loadNetworks() {
    var n = vm.networks;
    return Object.keys(n).map(function(network) {
      return {
        value: network.toLowerCase(),
        display: n[network].display
      };
    });
  }

  function querySearch (query) {
    var results = query ? networks.filter( createFilterFor(query) ) : networks,
      deferred;
    return results;
  }

  function createFilterFor(query) {
    var lowercaseQuery = angular.lowercase(query);

    return function filterFn(network) {
      return (network.value.indexOf(lowercaseQuery) === 0);
    };
  }

  function filterPrefixes(searchText) {
    var results = [];
    if(searchText.charAt(0) == '0' && searchText.charAt(1) == "9") {
      searchText = searchText.substr(1);
    }

    var filterItem = vm.cachedPrefixes[vm.tabToFilter];
    for(var i = 0; i < filterItem.length; i++) {
      for(var key in filterItem[i]) {
        if(typeof(filterItem[i][key]) == "string" && filterItem[i][key].indexOf(searchText)!=-1) {
          if(!itemExists(results, filterItem[i])) results.push(filterItem[i]);
        }
      }
    }
    vm.activePrefixes = results;
  }

  function compareObjects(o1, o2) {
    var k = '';
    for(k in o1) if(o1[k] != o2[k]) return false;
    for(k in o2) if(o1[k] != o2[k]) return false;
    return true;
  }

  function itemExists(haystack, needle) {
    for(var i=0; i<haystack.length; i++) if(compareObjects(haystack[i], needle)) return true;
    return false;
  }
}
}).call(this,require("rH1JPG"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/controllers/prefixctrl.js","/controllers")