(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
module.exports = usersCtrl;
// factory Auth
function usersCtrl($scope, $rootScope, $mdDialog, AuthService, PrefixService, ToastService, CreatorService, Var, users) {
  var vm = this;
  var auth = AuthService;
  var lastkey;
  var lastitem;

  vm.users = users;
  vm.selectThis = selectThis;
  vm.accounts = ["Admin", "User"];

  vm.submitUser = submitUser;
  vm.deleteUser = deleteUser;
  vm.editUser = editUser;
  vm.cancelEdit = cancelEdit;
  vm.disableUser = disableUser;
  vm.showPass = showPass;
  vm.showpassword = false;

  vm.formError = false;

  vm.showDialog = showDialog;
  vm.closeDialog = closeDialog;

  vm.cancelDelete = cancelDelete;

  function showDialog(ev, el, user , del) {
    $mdDialog.show({
      contentElement: el,
      parent: angular.element(document.body),
      targetEvent: ev,
      clickOutsideToClose: true,
      onShowing: function() {
        console.log("onshowing");
        vm.deletingUser = user;
      },
      onRemoving: function() {
        console.log("onremoving");
      }
    });
  }

  function closeDialog() {
    $mdDialog.hide();
  }

  function selectThis(item) {
    console.log(item);
  }

  function showPass() {
    vm.showpassword = vm.showpassword ? false : true;
    console.log(vm.showpassword);
  }

  function submitUser(valid) {
    var item;
    var obj = {
      firstname: vm.firstname,
      lastname: vm.lastname,
      email: vm.email,
      status: true
    };

    if (vm.editing) {

        if(vm.email !== lastitem.email) {
          updateEmail(vm.email);
        }
        
        obj.status = vm.status;
        item = Number(lastkey);
        PrefixService.saveUser(obj, true, item);
        clearField();
        ToastService.toast("User account updated");
    } else {
      if (vm.email && vm.password) {
        createUser(obj);
      }
    }
  }

  function createUser(obj) {
    CreatorService.createUser(vm.email, vm.password, obj).then(function(data) {
      clearField();
      ToastService.toast("User " + data.firstname + " created successfully!");
    }).catch(function(error) {
      console.error("Error: ", error);
      switch(error.code) {
        case "auth/email-already-in-use":
          ToastService.toast("The email address is already in use by another account");
          break;
        case "auth/weak-password":
          ToastService.toast("Password should be at least 6 characters");
          break;
      }
    });
  }

  function deleteUser() {
    var user = vm.deletingUser;
    if(vm.deletePassword) {
      user.password = vm.deletePassword;

      CreatorService.deleteUser(user).then(function(status) {
        closeDialog();
        vm.deletePassword = "";
        PrefixService.deleteUser(user).then(function(status) {
          console.log(status);
          ToastService.toast("Account delete");
        }).catch(function(error) {
          console.log(error);
        });

      }).catch(function(error) {
        console.log(error);
        if(error.code == "auth/wrong-password") {
          ToastService.toast("The password is invalid");
        }
      });
    } else {
      console.log("No password!");
      ToastService.toast("Please provide the account's password");
    }
  }

  function cancelDelete() {
    closeDialog();
    vm.deletePassword = "";
    vm.deletingUser = "";
  }

  function editUser(user, obj) {

    lastkey = getKey(user, obj);
    lastitem = user;
    vm.firstname = user.firstname;
    vm.lastname = user.lastname;
    vm.email = user.email;
    vm.type = user.type;
    vm.status = user.status;
    vm.editing = true;
  }

  function cancelEdit() {
    vm.editing = false;
    lastkey = "";
    clearField();
  }

  function disableUser(user, obj) {
    PrefixService.disableUser(user, obj);
  }

  function clearField() {
    vm.firstname = "";
    vm.lastname = "";
    vm.email = "";
    vm.password = "";
    vm.type = "";
    vm.status = "";
  }

  function getKey(value, obj){
    for(var key in obj){
      if(obj[key] == value){
        return key;
      }
    }
    return null;
  }
}
}).call(this,require("rH1JPG"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/controllers/usersctrl.js","/controllers")