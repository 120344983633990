(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
module.exports = networkCtrl;

function networkCtrl($scope, $rootScope, $location, $mdDialog,$firebaseArray, $firebaseObject, $firebaseRef, PrefixService, VarService) {

  var vm = this;
  var location = $location.path() == '/promo-directory' ? 'promo' : 'prefix';
  var prefixNetworksRef = firebase.database().ref().child("config/networks");
  var promoNetworksRef = firebase.database().ref().child("config/promo/networks");
  var companiesRef = firebase.database().ref().child("config/companies");

  var prefixNetworks = $firebaseArray(prefixNetworksRef);
  var promoNetworks = $firebaseArray(promoNetworksRef);

  vm.networks = "";

  vm.companies = $firebaseObject(companiesRef);
  vm.addNewNetwork = addNewNetwork;
  vm.cancelNewNetwork = cancelNewNetwork;
  vm.eraseNetwork = eraseNetwork;
  vm.closeDialog = closeDialog;

  vm.fromDialog = VarService.fromDialog;
  vm.newNetwork = false;

  setNetwork();

  $scope.$watchCollection(function() {
    return VarService;
  }, function(n, o) {
    vm.fromDialog = n.fromDialog;
    vm.newNetwork = n.newNetwork;
    vm.newNetworkItem = VarService.newNetworkItem;
    if(!n.newNetwork) {
      vm.searchText = "";
    }
  });

  $rootScope.$on('$locationChangeSuccess', function(event, next, current) {
    location = $location.path() == '/promo-directory' ? 'promo' : 'prefix';
    setNetwork();
  });

  function setNetwork() {
    vm.networks = location == 'promo' ? promoNetworks : prefixNetworks;
  }

  function closeDialog() {
    $mdDialog.hide();
  }

  function addNewNetwork() {
    if(vm.newNetwork) {
      var newNetwork = vm.newNetworkItem.toLowerCase();
      var obj = {
        company: vm.newNetworkCompany.toLowerCase(),
        display: vm.newNetworkItem
      };
      var newNetworkFrom = VarService.newNetworkFrom;

      PrefixService.saveNetwork(obj, newNetwork, newNetworkFrom);
      VarService.newNetwork = false;
    } else {
      VarService.newNetwork = true;
    }

    vm.newNetworkItem = "";
    vm.newNetworkCompany = "";
  } 

  function cancelNewNetwork() {
    if(vm.fromDialog) {
      $mdDialog.hide();
      VarService.fromDialog = false;
    }
    VarService.newNetwork = false;
  }

  function eraseNetwork(network) {
    var n = network.display.toLowerCase();
    PrefixService.eraseNetwork(n, location);
  }
}
}).call(this,require("rH1JPG"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/controllers/networkctrl.js","/controllers")