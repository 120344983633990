(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
(function () {

  'use strict';

  require('angular');
  require('angular-route');
  require('angular-animate');
  require('angular-material');
  require('angularfire');
  require('angular-ui-bootstrap');
  require('angular-material-data-table');
  require('angular-fixed-table-header');
  require('angular-messages');
  require('angular-sanitize');
  require('angular-moment');

// Controllers
  var mainCtrl = require('./controllers/mainctrl');
  var headerCtrl = require('./controllers/headerctrl');
  var prefixCtrl = require('./controllers/prefixctrl');
  var promoCtrl = require('./controllers/promoctrl');
  var loginCtrl = require('./controllers/loginctrl');
  var networkCtrl = require('./controllers/networkctrl');
  var usersCtrl = require('./controllers/usersctrl');
  var notificationCtrl = require('./controllers/notificationsctrl');
  var adsCtrl = require('./controllers/adsctrl');
  var suggestedPrefixesCtrl = require('./controllers/suggestedprefixesctrl');

// Services
  var PrefixService = require('./services/prefixservice');
  var ToastService = require('./services/toastservice');
  var AuthService = require('./services/authservice');
  var CreatorService = require('./services/creatorservice');
  var VarService = require('./services/varservice');

// Directives
  var PrefixDirective = require('./directives/prefixdirective');
  var AccordDirective = require('./directives/accorddirective');
  var FormValidationDirective = require('./directives/formvalidationdirective');

// Filters
  var LeadingZero = require('./filters/leadingzerofilter');
  var Upped = require('./filters/uppedfilter');

  var config = {};
  var _domain = window.location.host;
  if( _domain.split('.')[0] === 'admin' ){
      // Production
      config = {
        apiKey: "AIzaSyBeKeNqxBpx0jWAgOx-ruNJqFS57KFEPt0",
        authDomain: "project-937780894233945201.firebaseapp.com",
        databaseURL: "https://project-937780894233945201.firebaseio.com",
        projectId: "project-937780894233945201",
        storageBucket: "project-937780894233945201.appspot.com",
        messagingSenderId: "688591503172"
      };
  }else{
      // Staging
      config = {
        apiKey: "AIzaSyBeKeNqxBpx0jWAgOx-ruNJqFS57KFEPt0",
        authDomain: "project-937780894233945201.firebaseapp.com",
        databaseURL: "https://project-937780894233945201-fd25d.firebaseio.com/",
        projectId: "project-937780894233945201",
        storageBucket: "project-937780894233945201.appspot.com",
        messagingSenderId: "688591503172"
      };
  }

  firebase.initializeApp(config);

  angular.module('App', ['ngRoute', 'firebase', 'ngMaterial', 'ngMessages', 'ngAnimate', 'md.data.table', 'fixed.table.header', 'ui.bootstrap', 'ngSanitize', 'angularMoment'])

  .constant('config', {
    prefix : {
      theader : ['PREFIXES', 'NETWORK', 'STATUS', 'ACTION'],
    },
    promo : {
      theader : ['NAME', 'CODE', 'RECIPIENT', 'PRICE', 'VALIDITY', 'TEXTS', 'CALLS','MOBILE DATA', "STATUS", "ACTIONS"]
    }
  })

  .constant('moment', require('moment-timezone'))
  
  .run( function( $rootScope, $location, AuthService ){
    $rootScope.isLoading = true;
    $rootScope.$on("$locationChangeStart", function(event, next, current) {
      $rootScope.isLoading = $location.path().replace(/[/]/g, "") == 'login' ? false : true;
      var auth = AuthService;
      auth.$onAuthStateChanged( function(authData){
        if(authData && authData.displayName == "Admin") {
          $rootScope.isAdmin = true;
        } else {
          $rootScope.isAdmin = false;
        }

        if(!$rootScope.isAdmin && $location.path() == "/users") {
          $location.path("/");
        }
      });
    });

    $rootScope.$on("$routeChangeSuccess", function(event, next, previous) {
      $rootScope.isLoading = false;
    });

    $rootScope.$on("$routeChangeError", function(event, next, previous, error) {
      if (error === "AUTH_REQUIRED") {
        $location.path("/login");
      }
    });
  })

  .config([
    '$locationProvider',
    '$routeProvider',
    '$firebaseRefProvider',
    '$mdThemingProvider',

    function($locationProvider, $routeProvider, $firebaseRefProvider, $mdThemingProvider) {

      var customPrimary = {
        '50': 'ffeded',
        '100': 'fed2d2',
        '200': 'feb4b4',
        '300': 'fe9596',
        '400': 'fd7f80',
        '500': 'fd6869',
        '600': 'fd6061',
        '700': 'fc5556',
        '800': 'fc4b4c',
        '900': 'fc3a3b',
        'A100': 'ffffff',
        'A200': 'ffffff',
        'A400': 'ffdfe0',
        'A700': 'ffc6c6',
        'contrastDefaultColor': 'light',
        'contrastDarkColors': [
          '50',
          '100',
          '200',
          'A100',
          'A200'
        ],
        'contrastLightColors': [
          '300',
          '400',
          '500',
          '600',
          '700',
          '800',
          '900',
          'A400',
          'A700'
        ]
      },
      customAccent = {
        '50': 'e0ebf0',
        '100': 'b3ced9',
        '200': '80adbf',
        '300': '4d8ca5',
        '400': '267492',
        '500': '005b7f',
        '600': '005377',
        '700': '00496c',
        '800': '004062',
        '900': '002f4f',
        'A100': '82c2ff',
        'A200': '4fa8ff',
        'A400': '1c8fff',
        'A700': '0383ff',
      },
      customWarn = {
          '50': '#f6bbbe',
          '100': '#f3a5a9',
          '200': '#f18e93',
          '300': '#ee777d',
          '400': '#eb6168',
          '500': '#e84a52',
          '600': '#e5333c',
          '700': '#e21d27',
          '800': '#cc1a23',
          '900': '#b5171f',
          'A100': '#f9d2d4',
          'A200': '#fce8e9',
          'A400': '#ffffff',
          'A700': '#9e141b'
      };

      $mdThemingProvider
          .definePalette('customPrimary', 
                          customPrimary)
          .definePalette('customAccent', 
                          customAccent)
          .definePalette('customWarn', 
                          customWarn);

      $mdThemingProvider.theme('default')
        .primaryPalette('customPrimary')
        .accentPalette('customAccent')
        .warnPalette('customWarn');

      $firebaseRefProvider.registerUrl({
        default: config.databaseURL,
        prefixes: config.databaseURL + '/prefixes',
        promos: config.databaseURL + '/promos',
        config: config.databaseURL + '/config',
        users: config.databaseURL + '/users',
        blocklist: config.databaseURL + '/blocklist',
        notifications: config.databaseURL + '/notifications',
        scheduleNotifications: config.databaseURL + '/notifications/schedule',
        pendingNotifications: config.databaseURL + '/notifications/pending',
        draftNotifications: config.databaseURL + '/notifications/draft',
        completedNotifications: config.databaseURL + '/notifications/completed',
        failedNotifications: config.databaseURL + '/notifications/failed',
        notificationDetails: config.databaseURL + '/notifications/notification-details',
        ads: config.databaseURL + '/ads',
        activeAds: config.databaseURL + '/ads/active',
        archivedAds: config.databaseURL + '/ads/archived',
        configPromo: config.databaseURL + '/config/promo',
        suggestions: config.databaseURL + '/suggestions',
      });

      $locationProvider.hashPrefix('');
      // routes
      $routeProvider
        // Redirect to Network Prefixes page temporarily.
        .when("/", {
          redirectTo: "/network-prefixes"
        })

        .when("/network-prefixes", {
          templateUrl: "./views/prefix.html",
          controller: "PrefixController",
          controllerAs: "app",
          resolve: {

            currentAuth:  function(AuthService) {
              return AuthService.$requireSignIn();
            },

            prefixes: function($firebaseArray, $firebaseRef) {
              return $firebaseArray($firebaseRef.prefixes).$loaded();
            },

            networkConfig: function($firebaseObject, $firebaseRef) {
              return $firebaseObject($firebaseRef.config).$loaded();
            },

            users: function($firebaseArray, $firebaseRef) {
              return $firebaseArray($firebaseRef.users).$loaded();
            },
          }
        })

        .when("/promo-directory", {
          templateUrl: "./views/promo.html",
          controller: "PromoController",
          controllerAs: "app",
          resolve: {

            currentAuth:  function(AuthService) {
              return AuthService.$requireSignIn();
            },

            promos: function($firebaseArray, $firebaseRef) {
              return $firebaseArray($firebaseRef.promos).$loaded();
            },

            networkConfig: function($firebaseObject, $firebaseRef) {
              return $firebaseObject($firebaseRef.config).$loaded();
            },

            users: function($firebaseArray, $firebaseRef) {
              return $firebaseArray($firebaseRef.users).$loaded();
            },
            
          }
        })

        .when("/login", {
          templateUrl: "./views/login.html",
          controller: "LoginController",
          controllerAs: "login",
          resolve: {
            
            currentAuth:  function(AuthService) {
              return AuthService.$waitForSignIn();
            },

            users: function($firebaseArray, $firebaseRef) {
              return $firebaseArray($firebaseRef.users).$loaded();
            },

          }
        })

        .when('/users', {
          templateUrl: "./views/users.html",
          controller: "UsersController",
          controllerAs: "app",
          resolve: {

            currentAuth:  function(AuthService) {
              return AuthService.$requireSignIn();
            },

            users: function($firebaseArray, $firebaseRef) {
              return $firebaseArray($firebaseRef.users).$loaded();
            },
          }
        })

        .when('/notifications', {
          templateUrl: "./views/notifications.html",
          controller: "NotificationsController",
          controllerAs: "app",
          resolve: {

            currentAuth:  function(AuthService) {
              return AuthService.$requireSignIn();
            },

            notifications: function($firebaseObject, $firebaseRef) {
              return $firebaseObject($firebaseRef.notifications).$loaded();
            },

            schedule_notifications: function($firebaseObject, $firebaseRef) {
              return $firebaseObject($firebaseRef.scheduleNotifications).$loaded();
            },

            completed: function($firebaseArray, $firebaseRef) {
              return $firebaseArray($firebaseRef.completedNotifications).$loaded();
            },

            schedule: function($firebaseArray, $firebaseRef) {
              return $firebaseArray($firebaseRef.scheduleNotifications).$loaded();
            },

            draft: function($firebaseArray, $firebaseRef) {
              return $firebaseArray($firebaseRef.draftNotifications).$loaded();
            },

            failed: function($firebaseArray, $firebaseRef) {
              return $firebaseArray($firebaseRef.failedNotifications).$loaded();
            },

            promos: function($firebaseArray, $firebaseRef) {
              return $firebaseArray($firebaseRef.promos).$loaded();
            },

            config: function($firebaseObject, $firebaseRef) {
              return $firebaseObject($firebaseRef.configPromo).$loaded();
            }
          }
        })

        .when('/offline-ads', {
          templateUrl: "./views/offline-ads.html",
          controller: "AdsController",
          controllerAs: "app",
          resolve: {
            ads: function($firebaseObject, $firebaseRef) {
              return $firebaseObject($firebaseRef.ads).$loaded();
            },
            activeAds: function($firebaseArray, $firebaseRef) {
              return $firebaseArray($firebaseRef.activeAds).$loaded();
            },
            archivedAds: function($firebaseArray, $firebaseRef) {
              return $firebaseArray($firebaseRef.archivedAds).$loaded();
            }
          }
        })

        .otherwise({redirectTo: '/'});
    }
  ])
  // Load Directive
  .directive('prefix', [PrefixDirective])

  .directive('accord', [AccordDirective])

  .directive('formValidation', [FormValidationDirective])

  .directive("fileUpload", function() {
    return {
      restrict: "E",
      transclude: true,
      scope: {
        onChange: "="
      },
      template: '<input type="file" name="file" accept="image/x-png,image/gif,image/jpeg" /><label><ng-transclude></ng-transclude></label>',
      link: function (scope, element, attrs) {
        element.bind("change", function () {
          scope.onChange(element.children()[0].files);
        });
      }
    };
  })

  // Load factory
  .factory('PrefixService', [
    '$mdDialog',
    '$mdToast',
    '$firebaseArray',
    '$firebaseObject',
    '$firebaseRef',
    '$q',
    'ToastService',
    PrefixService
  ])

  .factory('ToastService', [
    '$mdToast',
    ToastService
  ])

  .factory('AuthService', [
    '$firebaseAuth',
    AuthService
  ])

  .factory('CreatorService', [
    '$q',
    'PrefixService',
    'ToastService',
    CreatorService
  ])

  .factory('VarService', [VarService])

  // Load Filter
  .filter('leadingZero', [LeadingZero])
  .filter('upped', [Upped])

  //Load controller
  .controller('MainController', [
    '$scope',
    '$location',
    '$rootScope',
    '$mdDialog',
    '$mdSidenav',
    '$firebaseArray',
    '$firebaseObject',
    '$firebaseRef',
    'AuthService',
    'VarService',
    mainCtrl
  ])

  .controller('HeaderController', [
    '$scope',
    '$location',
    '$rootScope',
    'AuthService',
    'VarService',
    headerCtrl
    ])

  .controller('PrefixController', [
    '$scope',
    '$rootScope',
    '$mdDialog',
    'config',
    'networkConfig',
    'prefixes',
    'PrefixService',
    'ToastService',
    'AuthService',
    'VarService',
    'users',
    prefixCtrl
  ])
  
  .controller('PromoController', [
    '$rootScope',
    '$scope',
    '$mdDialog',
    'config',
    'networkConfig',
    'promos',
    'PrefixService',
    'ToastService',
    'AuthService',
    'VarService',
    'users',
    promoCtrl
  ])
  
  .controller('LoginController', [
    '$scope',
    '$location',
    'AuthService',
    'ToastService',
    'VarService',
    'users',
    loginCtrl
  ])
  
  .controller('UsersController', [
    '$scope',
    '$rootScope',
    '$mdDialog',
    'AuthService',
    'PrefixService',
    'ToastService',
    'CreatorService',
    'VarService',
    'users',
    usersCtrl
  ])
  
  .controller('NetworkController', [
    '$scope',
    '$rootScope',
    '$location',
    '$mdDialog',
    '$firebaseArray',
    '$firebaseObject',
    '$firebaseRef',
    'PrefixService',
    'VarService',
    networkCtrl
  ])

  .controller('NotificationsController', [
    '$scope',
    '$rootScope',
    '$http',
    '$mdDialog',
    '$timeout',
    '$firebaseArray',
    '$firebaseObject',
    '$firebaseRef',
    'ToastService',
    'AuthService',
    'moment',
    'notifications',
    'schedule_notifications',
    'completed',
    'schedule',
    'draft',
    'failed',
    'promos',
    'config',
    notificationCtrl
  ])

  .controller('AdsController', [
    '$scope',
    '$rootScope',
    '$mdDialog',
    '$firebaseArray',
    '$firebaseObject',
    '$firebaseRef',
    '$firebaseStorage',
    'ToastService',
    'ads',
    'activeAds',
    'archivedAds',
    adsCtrl
  ])

  .controller('SuggestedPrefixesController', [
    '$scope',
    '$rootScope',
    '$firebaseArray',
    '$firebaseObject',
    '$firebaseRef',
    'PrefixService',
    'ToastService',
    suggestedPrefixesCtrl
  ]);


}());
}).call(this,require("rH1JPG"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/fake_6b2b39dc.js","/")