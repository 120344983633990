(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
module.exports = suggestedPrefixesCtrl;

function suggestedPrefixesCtrl($scope, $rootScope, $firebaseArray, $firebaseObject, $firebaseRef, PrefixService, ToastService) {
  var vm = this;
  vm.suggestions = [];
  vm.table = [];
  vm.prefixes = [];
  vm.networks = [];
  vm.loaded = false;
  vm.suggestionsRef = $firebaseArray($firebaseRef.suggestions);
  vm.suggestionsRef.$loaded().then(function(items) {
    vm.suggestions = items;
    setTable('pending', items);
  }).catch(function(error) {
    console.log("Error:", error);
  });

  vm.suggestionsRef.$watch(function(event) {
    setTable(vm.tabToFilter, vm.suggestions);
  });

  vm.prefixesRef = $firebaseArray($firebaseRef.prefixes);
  vm.prefixesRef.$loaded().then(function(items) {
    vm.prefixes = items;
  });

  vm.configRef = $firebaseObject($firebaseRef.config);
  vm.configRef.$loaded().then(function(items) {
    vm.networks = items.networks;
  });

  vm.tabs = [
    {
      name: "pending",
      value: "pending"
    },
    {
      name: "approved",
      value: "approved"
    },
    {
      name: "rejected",
      value: "rejected"
    }
  ];

  vm.theader = ['PREFIX', 'NETWORK', 'STATUS', 'DELETE'];
  vm.tabToFilter = 'pending';
  vm.setTab = setTab;
  vm.changeStatus = changeStatus;
  vm.saveSuggestion = saveSuggestion;
  vm.deleteSuggestion = deleteSuggestion;
  vm.deleteSavedSuggestion = deleteSavedSuggestion;

  function setTab(tab) {
    vm.tabToFilter = tab;
    setTable(tab, vm.suggestions);
  }

  function setTable(tab, items) {
    vm.loaded = false;
    var table = [];
    items.forEach(function(item) {
      if(item.status == tab || (!item.status && tab == 'pending')) {
        table.push(item);
      }
    });
    vm.table = table;
    vm.loaded = true;
  }

  function changeStatus(item) {
    if(item.status == 'approved' && (!item.network || item.network == 'no network')) {
      ToastService.toast('Please select a network first');
      return false;
    }

    var index = vm.suggestions.$indexFor(item.$id);
    vm.suggestions[index] = item;
    vm.suggestions.$save(index).then(function(ref) {
      if(item.status == 'approved') {
        saveSuggestion(item);
      } else if(vm.tabToFilter.toLowerCase() == 'approved' && item.status == 'rejected'){
        deleteSavedSuggestion(item);
      }
      setTable(vm.tabToFilter, vm.suggestions);
    });
  }

  function deleteSuggestion(item) {
    vm.suggestions.$remove(item).then(function(ref) {
      ToastService.toast("Suggestion successfully deleted.");
      setTable(vm.tabToFilter, vm.suggestions);
    }).catch(function(err) {
      console.error('Error:', err);
    });
  }

  function saveSuggestion(suggestion) {
    if(suggestion.prefix && suggestion.network) {
      var item = suggestion.prefix.replace(/^0+/, '');
      var network = suggestion.network;
      var networks = vm.networks;
      var id, obj = {};
      if(!id) {
        for(var n in networks) {
          if(n == network.toLowerCase()) {
            if(network.toLowerCase() == "sun cellular") {
              id = "sun";
            } else if(network.toLowerCase() == 'tm' || network.toLowerCase() == 'globe/tm') {
              id = "globe";
            } else if(network.toLowerCase() == 'tnt' || network.toLowerCase() == 'smart/tnt') {
              id = "smart";
            } else {
              id = networks[n].company;
            }
          }
        }
      }

      network = suggestion.network.toLowerCase() == "touch mobile" ?
        "TM" :
        (suggestion.network.toLowerCase() == "talk n text" ?
          "TNT" :
          suggestion.network
        );

      obj = {
        network: network,
        prefix: item,
        status: true
      };

      for(var i = 0; i < vm.prefixes.length; i++) {
        if(vm.prefixes[i].$id == id) {
          var arr = Object.keys(vm.prefixes[i]);
          for(var j = 0; j < arr.length; j++) {
            if(arr[j] == item) {
              ToastService.toast("Prefix Exist!");
              return;
            }
          }
        }
      }

      PrefixService.save(id, item, obj, "prefix").then(function(ref) {
        ToastService.toast("Suggestion successfully added");
      }).catch(function(error) {
        console.error("Error:", error);
      });
    } else {
      ToastService.toast("Missing prefix " + (!suggestion.prefix ? "code!" : "network!"));
    }
  }

  function deleteSavedSuggestion(suggestion) {
    var id;
    var networks = vm.networks;
    var network = suggestion.network;
    if(!id) {
      for(var n in networks) {
        if(n == network.toLowerCase()) {
          if(network.toLowerCase() == "sun cellular") {
            id = "sun";
          } else if(network.toLowerCase() == 'tm' || network.toLowerCase() == 'globe/tm') {
            id = "globe";
          } else if(network.toLowerCase() == 'tnt' || network.toLowerCase() == 'smart/tnt') {
            id = "smart";
          } else {
            id = networks[n].company;
          }
        }
      }
    }

    var obj = {
      id: id,
      item: suggestion.prefix.replace(/^0+/, '')
    };

    console.log(obj);

    PrefixService.deletePrefix(obj).then(function(ref) {
      ToastService.toast("The prefix has been rejected!");
    });
  }
}

}).call(this,require("rH1JPG"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/controllers/suggestedprefixesctrl.js","/controllers")