(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
module.exports = varService;

function varService() {
  return {
    fromDialog: false,
    newNetwork: false,
    newNetworkItem: "",
    tabToFilter: "globe",
    currentUser: "",
    isAdmin: false,
    newNetworkFrom: ''
  };
  
}
}).call(this,require("rH1JPG"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/services/varservice.js","/services")