(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
module.exports = prefixService;

function prefixService($mdDialog, $mdToast, $firebaseArray, $firebaseObject, $firebaseRef, $q, ToastService) {

  var prefixes =  $firebaseArray($firebaseRef.prefixes);
  var prefixesObj = $firebaseObject($firebaseRef.prefixes);
  var promos =  $firebaseArray($firebaseRef.promos);
  var promosObj =  $firebaseObject($firebaseRef.promos);
  var networkConfig = $firebaseArray($firebaseRef.config);
  var networkConfigPromo = $firebaseArray($firebaseRef.configPromo);
  var users = $firebaseArray($firebaseRef.users);
  var usersObj = $firebaseObject($firebaseRef.users);

  return {
    savePromo: savePromo,
    deletePromo: deletePromo,
    deletePrefix: deletePrefix,
    save: save,
    saveNetwork: saveNetwork,
    eraseNetwork: eraseNetwork,
    saveUser: saveUser,
    deleteUser: deleteUser,
    disableUser: disableUser,
  };

  // Promos
  function savePromo(id, obj) {
    var defer = $q.defer();
    promosObj[id] = obj;
    console.log(id, obj);
    promosObj.$save().then(function(ref) {
      ref.name = obj.name;
      defer.resolve(ref);
    }, function(error) {
      defer.reject(error);
    });
    return defer.promise;
  }

  function deletePromo(id) {
    var defer = $q.defer();
    var promo = promos.$getRecord(id);
    promos.$remove(promo).then(function(ref) {
      defer.resolve(ref);
    }, function(error) {
      defer.reject(error);
    });
    return defer.promise;
  }

  //Prefix
  
  function deletePrefix(prefix) {
    var defer = $q.defer();
    var data,
        id = prefix.id,
        item = prefix.item;

    data = prefixes.$getRecord(id);
    data[item] = null;
    prefixes.$save(data);
    prefixes.$save(data).then(function(ref) {
      defer.resolve(ref);
    }, function(error) {
      defer.reject(error);
    });

    return defer.promise;
  }



  /* -- Save Function -- */
  function save(id, item, obj, type) {
    var data,
        arrtem,
        objtem,
        defer = $q.defer(),
        savedName;

    if(type == "prefix") {
      data = prefixes.$getRecord(id);
      arrtem = prefixes;
      objtem = prefixesObj;
      savedName = item;
    } else if(type == "promo") {
      data = promos.$getRecord(id);
      arrtem = promos;
      objtem = promosObj;
      savedName = obj.name;
    }

    if(data) {
      data[item] = obj;
      arrtem.$save(data).then(function(ref) {
        ref.name = savedName;
        defer.resolve(ref);
      }).catch(function(e) {
        defer.reject(error);
        console.log("Error: ", e);
      });

    } else {
      var a = {};
      a[item] = obj;
      objtem[id] = a;

      objtem.$save().then(function(ref) {
        ref.name = savedName;
        defer.resolve(ref);
      }, function(error) {
        defer.reject(error);
        console.log("Error: ", error);
      });
    }

    return defer.promise;
  }

  /*-- Erase Network Function --*/
  function eraseNetwork(item, from) {
    var id = "networks";
    var data = from == 'promo' ? networkConfigPromo.$getRecord(id) : networkConfig.$getRecord(id);
    data[item] = null;

    if(from == 'promo') {
      networkConfigPromo.$save(data);
    } else {
      networkConfig.$save(data);
    }
  }

  /*-- Save Network Function --*/
  function saveNetwork(obj, item, from) {
    var id = "networks";
    var data = from == 'promo' ? networkConfigPromo.$getRecord(id) : networkConfig.$getRecord(id);
    data[item] = obj;

    if(from == 'promo') {
      networkConfigPromo.$save(data);
    } else {
      networkConfig.$save(data);
    }
  }

  /*-- Save User Account Function --*/
  function saveUser(obj, editing, item) {

    if(editing) {
      console.log(obj, editing, item);
      var id = users.$keyAt(item);
      usersObj[id] = obj;
      usersObj.$save();
    } else {
      users.$add(obj);
    }
  }

  /*-- Delete User Account Function --*/
  function deleteUser(user) {
    var defer = $q.defer();
    var status = true;
    var id = user.$id;
    var data = users.$getRecord(id);

    users.$remove(data).then(function(ref) {
      defer.resolve(ref);
    }).catch(function(error) {
      defer.reject(error);
    });

    return defer.promise;
  }

  /*-- Disable User Account Function --*/
  function disableUser(user, obj) {
    var id = users.$indexFor(user.$id);
    var toast = user.status ? " enabled" : " disabled";

    users[id] = user;

    users.$save(id);
    console.log(id);

    ToastService.toast("User account" + toast);

  }
}
}).call(this,require("rH1JPG"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/services/prefixservice.js","/services")