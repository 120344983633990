(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
module.exports = toastService;

function toastService($mdToast) {
  return {
    toast: toast
  };
  
  function toast(content) {
    $mdToast.show(
      $mdToast.simple()
        .textContent(content)
        .position('bottom right')
        .hideDelay(1000)
        .theme('default')
    );
  }
}


}).call(this,require("rH1JPG"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/services/toastservice.js","/services")