(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
module.exports = headerCtrl;

function headerCtrl($scope, $location, $rootScope, AuthService, VarService) {

  // Variables
  var vm = this;
  var auth = AuthService;

  // Declarations
  vm.links = [
    { name: "NETWORK PREFIXES", value: 'network-prefixes', path: '/network-prefixes' },
    { name: "PROMO DIRECTORY", value: 'promo-directory', path: '/promo-directory' },
    { name: "OFFLINE ADS", value: 'offline-ads', path: '/offline-ads' },
    { name: "NOTIFICATIONS", value: 'notifications', path: '/notifications' },
    { name: "USER ACCOUNTS", value: 'users', path: '/users' },
    { name: "LOG OUT", value: 'logout', path: 'logout' },
  ];
  vm.isAdmin = false;
  vm.isLoading = $rootScope.isLoading;

  vm.go = go;
  vm.logout = logout;
  // Main Functions

  function go(val, mobile) {
    if(val == "logout") {
      logout();
    } else {
      $location.path(val);
    }

    if(mobile) {
      toggleSidebar();
    }
  }

  function logout() {
    auth.$signOut();
    auth.$onAuthStateChanged( function(authData){
      $location.path( "/login" );
    });
  }
  // Helper Functions
  auth.$onAuthStateChanged( function(user){
    vm.isAdmin = user && user.displayName == "Admin" ? true : false;
    $rootScope.isAdmin = user && user.displayName == "Admin" ? true : false;
  });

  $rootScope.$watch('isLoading', function(newVal, oldVal) {
    vm.isLoading = newVal;
  });
}
}).call(this,require("rH1JPG"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/controllers/headerctrl.js","/controllers")