(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
module.exports = notificationsctrl;

function notificationsctrl($scope, $rootScope, $http, $mdDialog, $timeout, $firebaseArray, $firebaseObject, $firebaseRef, ToastService, AuthService, moment, notifications, schedule_notifications, completed, schedule, draft, failed, promos, config) {
  var vm = this,
      pendingNotifications = $firebaseArray($firebaseRef.pendingNotifications),
      failedNotifications = $firebaseArray($firebaseRef.failedNotifications),
      draftNotifications = $firebaseArray($firebaseRef.draftNotifications),
      failedNotificationsObj = $firebaseObject($firebaseRef.failedNotifications),
      draftNotificationsObj = $firebaseObject($firebaseRef.draftNotifications),
      Refresh;

  vm.timezones = require('google-timezones-json');
  vm.notifications = completed;
  vm.title = 'NOTIFICATIONS';
  vm.tab = {
    indicator: true,
    items: ['completed', 'schedule', 'draft', 'failed']
  };
  vm.table = {
    header: ['MESSAGE', 'STATUS', 'DELIVERY DATE', 'PLATFORM', 'TARGET ESTIMATE', 'OPEN RATE'],

    query: {
      order: 'deliveryDate',
      limit: 50,
      page: 1
    },
    
    options: {
      rowSelection: true,
      multiSelect: true,
      autoSelect: true,
      decapitate: false,
      largeEditDialog: false,
      boundaryLinks: false,
      limitSelect: true,
      pageSelect: true
    }
  };
  vm.tabToFilter = vm.tab.items[0];
  vm.notification = {
    title: null,
    body: null,
    delivery: {
      method: 'now',
      date: new Date(),
      time: new Date(1970, 0, 1, 12, 0, 0),
      timezone: vm.timezones[moment.tz.guess()]
    },
    target: 'multiple',
    platform: 'all',
    networkProvider: 'all',
    type: "general",
    userId: null,
    promoNetwork: null,
    promo: null
  };
  vm.emptyNotices = {
    completed: 'When you successfully send a notification',
    schedule: 'When you scheduled a notification',
    draft: 'When you draft a notification',
    failed: 'When a notification failed sending'
  };

  vm.searchText = null;
  vm.selectedItem = null;
  vm.isRefreshing = false;
  vm.disableBtn = true;
  vm.hideRefresh = true;
  vm.editingScheduled = false;
  vm.editingScheduledId = "";
  vm.editingDraft = false;
  vm.editingDraftId = "";
  vm.networks = config.networks;
  vm.emptyNotice = vm.emptyNotices.completed;
  vm.showDialog = showDialog;
  vm.closeDialog = closeDialog;
  vm.setTable = setTable;
  vm.isEmpty = isEmpty;
  vm.getNotificationCount = getNotificationCount;
  vm.sendNotification = sendNotification;
  vm.draftNotification = draftNotification;
  vm.deleteNotification = deleteNotification;
  vm.getNotificationDetails = getNotificationDetails;
  vm.editNotification = editNotification;
  vm.updateScheduled = updateScheduled;
  vm.draftScheduledNotification = draftScheduledNotification;
  vm.querySearch = querySearch;
  vm.setPromo = setPromo;
  vm.resetPromo = resetPromo;

  getNotificationDetails(true);
  setTable('completed');

  function showDialog(ev, target, id) {
    if(target !== 'delete') {
      $mdDialog.show({
        contentElement: target,
        parent: angular.element(document.body),
        clickOutsideToClose: false
      }).then(function() {
        clearFields();
      }, function() {
        clearFields();
      });
    } else {
      var content = '';
      if(vm.tabToFilter == 'schedule') {
        content = '<p>Deleting a scheduled notification will cancel the notification<br>and remove it from the database.<p>';
      } else {
        content = '<p>Deleting a notification will remove it from the database.</p>';
      }

      var confirm = $mdDialog.confirm()
        .title('DELETE NOTIFICATION?')
        .htmlContent(content)
        .ariaLabel('Delete Notification')
        .ok('Yes, Delete Notification')
        .cancel('Cancel');

      $mdDialog.show(confirm).then(function() {
        vm.deleteNotification(vm.tabToFilter, id);
      }, function() {
        console.log("Delete cancelled");
      });
    }
  }

  function closeDialog() {
    $mdDialog.hide();
  }

  function setTable(tab) {
    switch(tab) {
      case 'completed':
        vm.notifications = completed;
        break;
      case 'schedule':
        vm.notifications = schedule;
        break;
      case 'draft':
        vm.notifications = draft;
        break;
      case 'failed':
        vm.notifications = failed;
        break;
    }
    vm.emptyNotice = vm.emptyNotices[tab];
  }

  function getNotificationCount(tab) {
    return objectLength(notifications[tab]);
  }

  function objectLength(obj) {
    var count = 0;
    for(var k in obj) {
      if(obj.hasOwnProperty(k)) {
        ++count;
      }
    }
    return count;
  }

  function sendNotification(schedule) {
    if(!vm.notification.title && !vm.notification.body) {
      return ToastService.toast('Please check your input.');
    }
    var notification = {
      title: vm.notification.title,
      body: vm.notification.body,
      target: vm.notification.target,
      type: vm.notification.type,
    };

    if(vm.notification.target == 'multiple') {
      notification.platform =  vm.notification.platform;
      notification.networkProvider = vm.notification.networkProvider;
    } else {
      notification.userId = vm.notification.userId;
    }

    if(schedule) {
      var year = vm.notification.delivery.date.getFullYear(),
          month = vm.notification.delivery.date.getMonth(),
          day = vm.notification.delivery.date.getDate(),
          hours = vm.notification.delivery.time.getHours(),
          minutes = vm.notification.delivery.time.getMinutes() > 10 ? vm.notification.delivery.time.getMinutes() : vm.notification.delivery.time.getMinutes().toString() + '0',
          timezone = vm.notification.delivery.timezone.match(/(GMT)[-+](\d\d):(\d\d)/g).toString().split(":").join(""),
          scheduleDate = year + '-' + (month + 1) + '-' + day + ' ' + hours + ':' + minutes + ' ' + timezone;

      notification.scheduleDate = scheduleDate;
    }

    if(notification.type == "promo") {
      if(vm.notification.promo) {
        var promo = {
          code: vm.notification.promo.code,
          recipient: vm.notification.promo.recipient,
          price: vm.notification.promo.price,
          validity: vm.notification.promo.validity,
          text_short: vm.notification.promo.desc_text && vm.notification.promo.desc_text.short ? vm.notification.promo.desc_text.short : null,
          text_full: vm.notification.promo.desc_text && vm.notification.promo.desc_text.full ? vm.notification.promo.desc_text.full : null,
          call_short: vm.notification.promo.desc_call && vm.notification.promo.desc_call.short ? vm.notification.promo.desc_call.short : null,
          call_full: vm.notification.promo.desc_call && vm.notification.promo.desc_call.full ? vm.notification.promo.desc_call.full : null,
          data_short: vm.notification.promo.desc_data && vm.notification.promo.desc_data.short ? vm.notification.promo.desc_data.short : null,
          data_full: vm.notification.promo.desc_data && vm.notification.promo.desc_data.full ? vm.notification.promo.desc_data.full : null,
          status: vm.notification.promo.status,
          name: vm.notification.promo.name,
          network: vm.notification.promo.network
        };
        notification.promo = promo;
      } else {
        return ToastService.toast('Please check your input.');
      }
    }

    pendingNotifications.$add(notification).then(function(ref) {
      if(schedule && !vm.editingScheduled) {
        ToastService.toast('Success! Your notification has been added to scheduled list.');
      } else if(!vm.editingScheduled){
        ToastService.toast('Success! Your notification has been sent.');
      } else if(vm.editingScheduled && vm.notification.delivery.method == "now"){
        deleteNotification('schedule', vm.editingScheduledId);
        ToastService.toast('Success! Your notification has been sent.');
      } else {
        ToastService.toast('Success! The scheduled notification has been updated.');
      }

      var id;
      if(vm.editingDraft) {
        id = vm.editingDraftId;
        draftNotificationsObj[id] = null;
        draftNotificationsObj.$save();
      }

      if(vm.retryingFailed) {
        id = vm.retryId;
        failedNotificationsObj[id] = null;
        failedNotificationsObj.$save();
      }

      clearFields();
      closeDialog();
      vm.hideRefresh = false;
    }).catch(function(error) {
      console.error('Error:', error);
      ToastService.toast('Notification failed! Please try again.');
    });
  }

  function draftNotification() {
    // var id = vm.editingDraftId,
    //     status = vm.editingDraft;
    // if(status) {
      // draftNotificationsObj[id] = vm.notification;
      // draftNotificationsObj.$save().then(function(ref) {
      //   clearFields();
      //   closeDialog();
      //   ToastService.toast('Success! Your notification has been updated.');
      // }).catch(function(error) {
      //   console.error("Error:", error);
      // });
    // } else {
    //   draftNotifications.$add(vm.notification).then(function(ref) {
    //     notifications.schedule[vm.notification.id] = null;
    //     notifications.$save().then(function(){
    //       clearFields();
    //       closeDialog();
    //       ToastService.toast('Success! Your notification has been moved to draft.');
    //     });
    //   }).catch(function(error) {
    //     console.error('Error:', error);
    //   });
    // }
    
    if(vm.notification.delivery.method != "now") {
      var year = vm.notification.delivery.date.getFullYear(),
          month = vm.notification.delivery.date.getMonth(),
          day = vm.notification.delivery.date.getDate(),
          hours = vm.notification.delivery.time.getHours(),
          minutes = vm.notification.delivery.time.getMinutes() > 10 ? vm.notification.delivery.time.getMinutes() : vm.notification.delivery.time.getMinutes().toString() + '0',
          timezone = vm.notification.delivery.timezone.match(/(GMT)[-+](\d\d):(\d\d)/g).toString().split(":").join(""),
          scheduleDate = year + '-' + (month + 1) + '-' + day + ' ' + hours + ':' + minutes + ' ' + timezone;

      vm.notification.deliveryDate = vm.notification.delivery.date.getTime();
      vm.notification.scheduleDate = scheduleDate;
    }

    if(vm.editingDraft) {
      var id = vm.editingDraftId;
      draftNotificationsObj[id] = vm.notification;
      draftNotificationsObj.$save().then(function(ref) {
        clearFields();
        closeDialog();
        ToastService.toast('Success! Your draft notification has been updated.');
      }).catch(function(error) {
        console.error("Error:", error);
      });
    } else {
      draftNotifications.$add(vm.notification).then(function(ref) {
        if(vm.editingScheduled) {
          notifications.schedule[vm.notification.id] = null;
          notifications.$save().then(function(){
            ToastService.toast('Success! Your notification has been moved to draft.');
          });
        } else {
          ToastService.toast('Success! Your notification has been saved to draft.');
        }
        clearFields();
        closeDialog();
      }).catch(function(error) {
        console.error('Error:', error);
      });
    }
  }

  function deleteNotification(tab, id) {
    var editingScheduled = vm.editingScheduled;
    var ref = tab == 'completed' ? $firebaseRef.completedNotifications :
            ( tab == 'schedule' ? $firebaseRef.scheduleNotifications : 
            ( tab == 'draft' ? $firebaseRef.draftNotifications : $firebaseRef.failedNotifications ) );

    var refData = $firebaseArray(ref);
    refData.$loaded().then(function(data) {
      var item = data.$getRecord(id);
      if(tab == 'schedule') {
        var config = {
          params: {
            id: id
          }
        };
        $http.get('https://us-central1-project-937780894233945201.cloudfunctions.net/cancelNotification', config).then(function(data) {
          if(!editingScheduled) {
            ToastService.toast('Success! Your notification has been deleted.');
            editingScheduled = false;
          }
        }).catch(function(error) { console.error(error); });
      } else {
        refData.$remove(item).then(function(ref) {
          ToastService.toast('Success! Your notification has been deleted.');
        });
      }
    });
  }

  function getNotificationDetails(fromLoad) {
    var timestamp = Math.floor(new Date() / 1000),
        timeUpdated = notifications.updated,
        updatedKey;

    var timeRemaining = timestamp - timeUpdated;
    if(!timeUpdated || timeRemaining >= 600) {
      if(!fromLoad) {
        vm.isRefreshing = true;
      }

      $http.get('https://us-central1-project-937780894233945201.cloudfunctions.net/viewNotifications').then(function(data) {
        if(data.status == 200) {
          vm.isRefreshing = false;
          obj = $firebaseObject($firebaseRef.notifications);
          obj.$loaded().then(function(notifications) {
            $timeout(function() {
              notifications.updated = timestamp;
              notifications.$save().then(function(ref) {
                if(!fromLoad) {
                  ToastService.toast('Success! Notification data has been updated.');
                }
              }, function(error) {
                console.log("Error:", error);
              });
            }, 3000);
          });
          if(Refresh) {
            $timeout.cancel(Refresh);
          }
          refreshTrigger(300000);
        }
      }).catch(function(error) { vm.isRefreshing = false; });
    } else {
      if(!fromLoad) {
        var minutesRemaining = Math.floor((600 - timeRemaining) / 60);
        minutesRemaining = minutesRemaining > 1 ? (minutesRemaining + ' minutes.') : (minutesRemaining + ' minute.');
        ToastService.toast('Data is already updated. Please try again in ' + minutesRemaining);
      }
      var time = Math.floor((600 - timeRemaining) * 1000);
      if(Refresh) {
        $timeout.cancel(Refresh);
      }
      refreshTrigger(time);
    }
  }

  function updateScheduled(){
    var year = vm.notification.delivery.date.getFullYear(),
        month = vm.notification.delivery.date.getMonth(),
        day = vm.notification.delivery.date.getDate(),
        hours = vm.notification.delivery.time.getHours(),
        minutes = vm.notification.delivery.time.getMinutes() > 10 ? vm.notification.delivery.time.getMinutes() : vm.notification.delivery.time.getMinutes().toString() + '0',
        timezone = vm.notification.delivery.timezone.match(/(GMT)[-+](\d\d):(\d\d)/g).toString().split(":").join(""),
        scheduleDate = year + '-' + (month + 1) + '-' + day + ' ' + hours + ':' + minutes + ' ' + timezone;

    vm.notification.deliveryDate = vm.notification.delivery.date.getTime();
    vm.notification.scheduleDate = scheduleDate;

    deleteNotification('schedule', vm.editingScheduledId);
    sendNotification('schedule');
  }

  function editNotification(id, type) {
    vm.showDialog(false, '#createNewNotification');
    if(type === "draft") {
      vm.notification =  notifications.draft[id];
    } else if(type === "schedule") {
      vm.editingScheduled = true;
      vm.editingScheduledId = id;
      vm.notification =  notifications.schedule[id];
    }

    console.log(vm.notification);

    if(vm.tabToFilter === 'draft') {
      vm.editingDraftId = id;
      vm.editingDraft = true;
    } else if(vm.tabToFilter === 'failed') {
      vm.retryId = id;
      vm.retryingFailed = true;
    }

    if(vm.notification.delivery === 'now') {
      vm.notification.delivery = {
        method: 'now'
      };
    } else {
      vm.notification.delivery = {
        method: 'later',
        date: vm.notification.scheduleDate ? new Date(vm.notification.scheduleDate) : new Date(),
        time: new Date(vm.notification.scheduleDate),
        timezone: vm.timezones[moment.tz.guess()]
      };
    }

    if(vm.notification.promo) {
      vm.selectedItem = vm.notification.promo;
      vm.searchText = vm.notification.promo.code;
      vm.notification.promoNetwork = vm.notification.promo.network.toLowerCase();
    }
  }

  function draftScheduledNotification() {
    deleteNotification('schedule', vm.editingScheduledId);
    draftNotification();
  }


  function refreshTrigger(time) {
    vm.hideRefresh = true;
    Refresh = $timeout(function() {
      vm.hideRefresh = false;
    }, time);
  }

  function resetRefreshTrigger() {
    clearTimeout(Refresh);
  }

  function isEmpty(obj) {
    for(var prop in obj) {
        if(obj.hasOwnProperty(prop))
            return false;
    }
    return JSON.stringify(obj) === JSON.stringify({});
  }

  function clearFields() {
    vm.notification = {
      title: null,
      body: null,
      delivery: {
        method: 'now',
        date: new Date(),
        time: new Date(1970, 0, 1, 12, 0, 0),
        timezone: vm.timezones[moment.tz.guess()]
      },
      target: 'multiple',
      platform: 'all',
      networkProvider: 'all',
      type: "general",
      userId: null,
      promoNetwork: null,
      promo: null
    };
    vm.editingDraftId = null;
    vm.editingDraft = false;
    vm.editingScheduled = false;
    vm.editingScheduledId = false;
    vm.retryId = null;
    vm.retryingFailed = false;
    vm.searchText = null;
    vm.selectedItem = null;
  }

  function querySearch(query) {
    var filterPromos = vm.notification.promoNetwork ? promos.filter(function(promo) {
      return promo.network.toLowerCase() == vm.notification.promoNetwork.toLowerCase();
    }) : promos;
    var results = query ? filterPromos.filter(function(promo) {
      var lowercaseQuery = query.toLowerCase();
      return (promo.code.toLowerCase().indexOf(lowercaseQuery) >= 0);
    }) : filterPromos;
    return results;
  }

  function resetPromo() {
    vm.notification.promo = null;
    vm.selectedItem = null;
    vm.searchText = null;
  }

  function setPromo(promo) {
    if(promo) {
      vm.notification.promo = promo;
      for (var key in vm.networks) {
        if(key == vm.notification.promo.network.toLowerCase()) {
          vm.notification.networkProvider = vm.networks[key].company;
        }
      }
    }
  }

  notifications.$watch(function(ev) {
    setTable(vm.tabToFilter);
  });
}
}).call(this,require("rH1JPG"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/controllers/notificationsctrl.js","/controllers")