(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
module.exports = promoCtrl;

function promoCtrl(
  $rootScope,
  $scope,
  $mdDialog,
  config,
  networkConfig,
  promos,
  PrefixService,
  ToastService,
  AuthService,
  VarService,
  users
) {
  var vm = this;
  var lastItem;
  var promoTypes = {
    globe: [
      "call",
      "text",
      "unlimited call & text",
      "call & text combos",
      "mobile internet"
    ],
    smart: [
      "call",
      "text",
      "unlimited call & text",
      "call & text combos",
      "mobile internet",
      "international"
    ],
    "sun cellular": [
      "call",
      "text",
      "unlimited call & text",
      "call & text combos",
      "add ons",
      "mobile internet"
    ],
    "touch mobile": [
      "call",
      "text",
      "call & text combos",
      "add ons",
      "mobile internet",
      "games"
    ],
    "talk n text": ["call", "text", "unlimited call & text", "mobile internet"],
    "abs-cbn mobile": [
      "call",
      "text",
      "unlimited call & text",
      "mobile internet",
      "iwant tv"
    ],
    "cherry mobile": [
      "call",
      "text",
      "unlimited call & text",
      "mobile internet"
    ]
  };

  vm.promo = {
    code: null,
    desc_call: {
      full: null,
      short: ""
    },
    desc_data: {
      full: null,
      short: ""
    },
    desc_text: {
      full: null,
      short: ""
    },
    name: null,
    network: null,
    price: null,
    recipient: null,
    status: true,
    type: {},
    validity: null
  };
  vm.title = "PROMO DIRECTORY";
  vm.tabs = [
    "globe",
    "smart",
    "sun cellular",
    "touch mobile",
    "talk n text",
    "abs-cbn mobile",
    "cherry mobile"
  ];
  vm.tabToFilter = VarService.tabToFilter;
  vm.promos = promos;
  vm.networks = networkConfig.promo.networks;
  vm.companies = networkConfig.promo.companies;
  vm.theader = config.promo.theader;
  vm.tabToFilter = "globe";
  vm.editing = false;
  vm.formError = false;
  vm.promoTypeRequireMatch = true;
  vm.currentFilterNavItem = "view all";
  vm.filterBarNoInk = true;
  vm.openAccord = false;
  vm.viewTable = true;
  vm.promoType = [];
  vm.promoTypeList = [];

  vm.setTable = setTable;
  vm.toggleAccord = toggleAccord;
  vm.savePromo = savePromo;
  vm.deletePromo = deletePromo;
  vm.editPromo = editPromo;
  vm.cancelEdit = cancelEdit;
  vm.resetForm = resetForm;
  vm.querySearch = querySearch;
  vm.setNetwork = setNetwork;
  vm.queryTypes = queryTypes;
  vm.filterPromoTypeList = setFilterPromoType(vm.tabToFilter);
  vm.changeStatus = changeStatus;
  vm.filterPromosByType = filterPromosByType;
  vm.showDialog = showDialog;
  vm.closeDialog = closeDialog;
  var networks = loadNetworks();

  promos.$watch(function(event) {
    setTable();
  });

  $scope.$watchCollection(
    function() {
      return VarService;
    },
    function(n, o) {
      if (!n.newNetwork) {
        vm.searchText = "";
      }
      vm.currentFilterNavItem = "view all";
      vm.tabToFilter = n.tabToFilter;
      vm.promos = filterPromosByNetwork(vm.tabToFilter);
      vm.filterPromoTypeList = setFilterPromoType(vm.tabToFilter);
    }
  );

  networkConfig.$watch(function(ev) {
    vm.networks = networkConfig.promo.networks;
    networks = loadNetworks();
  });

  function setTable(tab) {
    vm.tabToFilter = tab ? tab : vm.tabToFilter;
    vm.promos = filterPromosByNetwork(vm.tabToFilter);
    vm.filterPromoTypeList = setFilterPromoType(vm.tabToFilter);
  }

  function showDialog(ev, el, newNetwork) {
    $mdDialog.show({
      contentElement: el,
      parent: angular.element(document.body),
      targetEvent: ev,
      clickOutsideToClose: true,
      onShowing: function() {
        if (newNetwork) {
          VarService.newNetwork = true;
          VarService.fromDialog = true;
          VarService.newNetworkItem = vm.searchText;
        }

        if (el == "#networkDialog") {
          VarService.newNetworkFrom = "promo";
        }
      },
      onRemoving: function() {
        if (VarService.newNetwork) {
          VarService.newNetwork = false;
        }
        cancelEdit();
      }
    });
  }

  function closeDialog() {
    $mdDialog.hide();
  }

  function filterPromosByNetwork(network) {
    return promos.filter(function(promo) {
      return promo.network.toLowerCase() == network.toLowerCase();
    });
  }

  function filterPromosByType(type) {
    var p = filterPromosByNetwork(vm.tabToFilter);
    vm.currentFilterNavItem = type;
    vm.promos = p.filter(function(promo) {
      if (promo.type && type !== "view all") {
        for (var key in promo.type) {
          if (key == type) {
            return true;
          }
        }
      } else {
        return true;
      }
    });
  }

  function toggleAccord() {
    $rootScope.$emit("ToggleAccord");
    if (vm.openAccord) {
      vm.cancelEdit();
    }
    vm.openAccord = !vm.openAccord;
  }

  function savePromo() {
    if (
      vm.promo.code &&
      vm.promo.recipient &&
      vm.promo.network &&
      vm.promo.name &&
      vm.promoType &&
      vm.promo.validity &&
      vm.promo.price
    ) {
      var id = vm.editing ? lastItem : vm.promo.code.toLowerCase();
      vm.promo.type = {};
      for (var t in vm.promoType) {
        vm.promo.type[vm.promoType[t]] = true;
      }
      PrefixService.savePromo(id, vm.promo)
        .then(function(ref) {
          ToastService.toast(
            "Promo " +
              ref.name +
              " has been " +
              (vm.editing ? "updated" : "added") +
              "!"
          );
          resetForm();
          toggleAccord();
          closeDialog();
        })
        .catch(function(error) {
          console.error(error);
          ToastService.toast("Adding promo failed. Please try again.");
        });
    } else {
      ToastService.toast("Please check your input.");
      vm.formError = true;
    }
  }

  function deletePromo(promo) {
    var confirm = $mdDialog
      .confirm()
      .title("Delete this promo?")
      .htmlContent("<b>" + promo.name + "</b> will be deleted permanently.")
      .ariaLabel("delete promo")
      .targetEvent(event)
      .ok("Delete")
      .cancel("Cancel");

    $mdDialog.show(confirm).then(
      function() {
        PrefixService.deletePromo(promo.$id)
          .then(function(ref) {
            ToastService.toast("Promo " + promo.name + " has been deleted!");
          })
          .catch(function(err) {
            console.error(error);
            ToastService.toast("Deleting promo failed. Please try again.");
          });
      },
      function() {
        console.log("Canceled Delete");
      }
    );
  }

  function editPromo(promo, ev, el) {
    var windowWidth = $(window).width();

    if (windowWidth <= 980) {
      vm.showDialog(ev, el);
    }

    var types = [],
      network = promo.network.toLowerCase(),
      networks = vm.networks;

    if (!vm.openAccord) {
      vm.toggleAccord();
    }

    if (promo.type && !Array.isArray(promo.type)) {
      var type = promo.type;
      for (var key in type) {
        types.push(key);
      }
      vm.promoType = types ? types : [];
    }

    vm.selectedItem = {
      value: network,
      display: networks[network].display
    };
    lastItem = promo.$id;
    vm.promo = promo;

    vm.editing = true;
    vm.formError = false;

    focusField();
  }

  function focusField() {
    angular.element("html,body").animate(
      {
        scrollTop: 0
      },
      600,
      function() {
        $(".form__container form input[name='name']").focus();
      }
    );
  }

  function changeStatus(promo) {
    promos
      .$save(promo)
      .then(function(ref) {
        var toast = promo.status ? "Promo enabled." : "Promo disabled.";
        ToastService.toast(toast);
      })
      .catch(function(err) {
        ToastService.toast("Error: " + err);
      });
  }

  function cancelEdit() {
    resetForm();
  }

  function resetForm() {
    vm.promo = {
      code: null,
      desc_call: {
        full: null,
        short: ""
      },
      desc_data: {
        full: null,
        short: ""
      },
      desc_text: {
        full: null,
        short: ""
      },
      name: null,
      network: null,
      price: null,
      recipient: null,
      status: true,
      type: {},
      validity: null
    };
    lastItem = null;
    vm.network = null;
    vm.selectedItem = null;
    vm.searchText = null;
    vm.editing = false;
    vm.promoType = [];
    vm.promoTypeList = [];
  }

  function setNetwork(network) {
    if (network) {
      vm.promo.network = network.display;
      setChips(network.display);
      vm.showPromoType = true;
    }
  }

  function setFilterPromoType(network) {
    var types = promoTypes;
    for (var key in types) {
      if (key.toLowerCase() == network.toLowerCase()) {
        return types[key];
      }
    }
  }

  function setChips(network) {
    if (!network) {
      vm.promoTypeList = [];
      return;
    }

    var chips = promoTypes;
    for (var key in chips) {
      if (key.toLowerCase() == network.toLowerCase()) {
        vm.promoTypeList = chips[key];
        return;
      }
    }
  }

  function loadNetworks() {
    var n = vm.networks;
    return Object.keys(n).map(function(network) {
      return {
        value: network.toLowerCase(),
        display: n[network].display
      };
    });
  }

  function querySearch(query) {
    var results = query ? networks.filter(function(network) {
      var lowercaseQuery = query.toLowerCase();
      return (network.value.indexOf(lowercaseQuery) >= 0);
    }) : networks;

    return results;
  }

  function queryTypes(query) {
    var results;

    if (query) {
      results = vm.promoTypeList.filter(function(el, indx, arr) {
        return el.indexOf(query.toLowerCase()) === 0;
      });
    } else {
      results =
        vm.promoType.length > 0 ? vm.promoTypeList.filter(function(el, idx, arr) {
              var stat = true;

              for (var i = 0; i < vm.promoType.length; i++) {
                if (el == vm.promoType[i]) {
                  stat = false;
                }
              }

              return stat;
            })
          : vm.promoTypeList;
    }
    return results;
  }

  // Helper Functions
  // Get object key fn
  function getKey(value, obj) {
    for (var key in obj) {
      if (obj[key] == value) {
        return key;
      }
    }
    return null;
  }

  $("#promo-table").on('scroll', function (e) {
      $("#table-scroller").scrollLeft($("#promo-table").scrollLeft());
  });
  $("#table-scroller").on('scroll', function (e) {
      $("#promo-table").scrollLeft($("#table-scroller").scrollLeft());
  });

}

}).call(this,require("rH1JPG"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/controllers/promoctrl.js","/controllers")