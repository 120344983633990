(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
module.exports = loginCtrl;
// factory Auth
function loginCtrl($scope, $location, AuthService, ToastService, VarService, users) {
  var vm = this;
  var auth = AuthService;
  vm.submitLogin = submitLogin;
  vm.submitReset = submitReset;
  vm.resetPassword = resetPassword;
  vm.backToLogin = backToLogin;
  vm.goToReset = goToReset;
  vm.email = "";
  vm.password = "";
  vm.error = false;
  vm.resetPass = false;
  vm.resetPassSuccess = false;
  vm.loading = false;

  vm.showPass = showPass;
  vm.showpassword = false;



  auth.$onAuthStateChanged( function(authData){
    if( authData !== null && authData.uid !== null ){
      $location.path( "/" );
    }
  });

  function showPass() {
    vm.showpassword = vm.showpassword ? false : true;
    console.log(vm.showpassword);
  }

  function submitLogin(valid) {

    if(vm.email && vm.password && valid) {
      vm.error = false;
      vm.loading = false;
      // login();

      if(users.length > 0) {
        console.log(users);
        var nomatch = true;
        for(var i = 0; i < users.length; i++) {
          var user = users[i];
          if(vm.email == user.email) {
            nomatch = false;
            if(!user.status) {
              ToastService.toast("Account is currently disabled.");
              vm.error = true;
              vm.loading = false;
              console.log("Match!");
            } else {
              login();
            }
          }
        }

        if(nomatch) {
          login();
          console.log("No Match!");
        } 

      } else if(users.length <= 0){
        console.log("No users");
        login();
      }

    } else if(!valid) {
      vm.error = true;
      vm.loading = false;
    }
  }

  function submitReset(valid) {
    if(vm.resetPassEmail && valid) {
      vm.error = false;
      vm.loading = false;
      resetPassword();
    } else if(!valid) {
      vm.error = true;
      vm.loading = false;
    }
  }

  function login() {
    vm.loading = true;
    auth.$signInWithEmailAndPassword(vm.email, vm.password).then(function(firebaseUser) {
      vm.error = false;
      vm.loading = false;
      ToastService.toast("Signed in as " + vm.email);
    }).catch(function(error) {
      vm.error = true;
      vm.loading = false;
      vm.password = "";
      console.log(error);
    });
    
  }

  function resetPassword() {
    vm.loading = true;
    auth.$sendPasswordResetEmail(vm.resetPassEmail).then(function() {
      vm.resetPassSuccess = true;
      vm.resetPass = false;
      vm.error = false;
      vm.loading = false;
    }).catch(function(error) {
      vm.error = true;
      vm.loading = false;
      console.log(error);
    });
  }

  function goToReset() {
    vm.resetPass = true;
    vm.error = false;
    vm.password = "";
    vm.resetPassEmail = vm.email ? vm.email : "";
  }

  function backToLogin() {
    vm.resetPass = false;
    vm.resetPassSuccess = false;
    vm.error = false;
    vm.password = "";
    vm.email = vm.resetPassEmail ? vm.resetPassEmail : "";
  }
}
}).call(this,require("rH1JPG"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/controllers/loginctrl.js","/controllers")